import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import { FaFacebookF, FaPhone, FaEnvelope } from "react-icons/fa"
import { Link } from "gatsby"

const Contact = () => (
  <Layout pageInfo={{ pageName: "contact", pageTitle: "Contact" }}>
    <Seo title="Contact"/>


    <Container>
      <section className="general-page" id="contact">
        <Row>
          <Col sm={{ span: 12 }} lg={{ span: 6 }}>
            <div className="block">
              <p>
                Wondere Wereld van Tekeningen
                <br/>
                gevestigd te Steenbergen, Noord-Brabant
              </p>
              <p>
                <a href="tel:+31614627951">
                  <FaPhone/> +31 6 14 62 79 51
                </a>
              </p>
              <p>
                <a href="mailto:info@wonderewereldvantekeningen.nl">
                  <FaEnvelope/> info@wonderewereldvantekeningen.nl
                </a>
              </p>
              <p>
                <a href="https://www.facebook.com/wonderewereldvantekeningen/"
                   aria-label="Vind ons op Facebook"
                   className="facebook">
                  <FaFacebookF/> facebook.com/wonderewereldvantekeningen
                </a>
              </p>


              <p>
                {/*KvK: 82490651*/}
                {/*<br/>*/}
                {/*BTW: NL003691129B84*/}
                {/*<br/>*/}
                <br/>
                <Link to="/algemene-voorwaarden">Algemene Voorwaarden</Link>.
                <br/>
                AVG: Wij gaan conform de AVG om met persoonsgegevens.
              </p>
            </div>
          </Col>
          <Col sm={{ span: 12 }} lg={{ span: 6 }}>
            <div className="block contact-form">
              <p>
                {/*<form name="Contactformulier Wondere Wereld van Tekeningen"*/}
                {/*      id="contactform"*/}
                {/*      action="/thank-you"*/}
                {/*      className="tr"*/}
                {/*      netlify="true"*/}
                {/*      method="POST"*/}
                {/*      netlify-honeypot="not-today">*/}

                {/*  <button className="btn-send" type="submit">Verzenden</button>*/}
                {/*</form>*/}

                <form name="form-contact" action="/thank-you" className="tr"
                      netlify="true" method="POST" netlify-honeypot="not-today">
                    <div>
                      <label>Naam</label>
                      <input name="name"/>
                    </div>
                    <div>
                      <label>E-mailadres</label>
                      <input type="email" name="email"/>
                    </div>
                    <div>
                      <label>Bericht</label>
                      <textarea rows="6" name="message"/>
                    </div>

                  <input name="not-today" hidden/>
                  <input name="form-name" value="form-contact" hidden/>
                  <button className="btn-send" type="submit">Verzenden</button>
                </form>

              </p>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  </Layout>
)

export default Contact
